import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FilteredCreditApplication } from '@src/api/credit-api'
import { Link } from 'react-router-dom'
import { ManageCoappButton } from '@src/components'
import RemoveCoappButton from '@src/components/RemoveCoappButton'

type Props = {
  creditApp: FilteredCreditApplication
}

const StyledList: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <ul style={{ listStyle: 'none', padding: 0 }}>{children}</ul>
)

const StyledListItem: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <li style={{ alignItems: 'center', marginBottom: '1rem', paddingLeft: '2rem' }}>
    <span>{children}</span>
  </li>
)

const PageTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div style={{ marginBottom: '2rem' }}>
    <h1 className="h3" style={{ marginBottom: '2rem' }}>
      {children}
    </h1>
  </div>
)

const HelpSection: React.FC = () => {
  return (
    <p style={{ marginTop: '5rem', textAlign: 'center' }}>
      <Trans
        i18nKey="loan.youHaveQuestions"
        values={{ phoneNumber: '1-888-689-9876' }}
        components={{ a: <Link to="tel:18886899876" style={{ whiteSpace: 'nowrap' }} /> }}
      />
    </p>
  )
}

type FailedSectionProps = {
  creditApp: FilteredCreditApplication
  hasCoapplicant: boolean
}

const CoappFailedReportSection: React.FC<FailedSectionProps> = ({ creditApp, hasCoapplicant }) => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t('identity.additionalInformation')}</PageTitle>
      <p style={{ marginBottom: '2rem' }}>
        {creditApp.applicant.hasCreditReport ? t('identity.coAppNoReport') : t('identity.AppAndCoAppNoReport')}
      </p>

      <StyledList>
        {creditApp.applicant.hasCreditReport ? (
          <>
            <p style={{ marginBottom: '2rem' }}>{t('identity.toContinue')}</p>
            <StyledListItem>{t('identity.proceedWithoutCoApp')}</StyledListItem>
            <div style={{ margin: '1rem 0', color: '#666', fontStyle: 'italic', paddingLeft: '2rem' }}>
              {t('identity.or')}
            </div>
            <StyledListItem>{t('identity.provideCoAppDetail')}</StyledListItem>
          </>
        ) : (
          <StyledListItem>{t('identity.applicantNoReportContinue')}</StyledListItem>
        )}
      </StyledList>
      <div className="btn-group" style={{ marginTop: '32px' }}>
        {hasCoapplicant && creditApp.applicant.hasCreditReport && <RemoveCoappButton creditAppId={creditApp.id} />}
        <ManageCoappButton creditAppId={creditApp.id} useChangeLabel={hasCoapplicant} />
      </div>
    </>
  )
}

const ApplicantFailedReportSection: React.FC<FailedSectionProps> = ({ creditApp, hasCoapplicant }) => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t('identity.couldNotVerifyReport')}</PageTitle>
      <div style={{ marginBottom: '2rem' }}>
        <p style={{ marginBottom: '2rem' }}>{t('identity.couldNotLocateReport')}</p>
        <StyledList>
          <StyledListItem>{t('identity.updateInformation')}</StyledListItem>
          <div style={{ margin: '1rem 0', color: '#666', fontStyle: 'italic', paddingLeft: '2rem' }}>
            {t('identity.or')}
          </div>
          <StyledListItem>
            {t(hasCoapplicant ? 'identity.updateCoapplicant' : 'identity.addCoapplicant')}
          </StyledListItem>
        </StyledList>
        <div className="btn-group" style={{ marginTop: '32px' }}>
          <Link
            className="btn secondary-action-btn btn-simple"
            to={`/${creditApp.merchantId}/creditapplication/${creditApp.id}/editPersonalInformation/personal-information`}
          >
            {t('identity.updateInfo')}
          </Link>
          <ManageCoappButton creditAppId={creditApp.id} useChangeLabel={hasCoapplicant} />
        </div>
      </div>
    </>
  )
}

const FailedToObtainCreditReportForm: React.FC<Props> = ({ creditApp }) => {
  const hasCoapplicant = creditApp.coapplicant != null

  return (
    <main className="general-message" style={{ display: 'block' }}>
      {hasCoapplicant && !creditApp.coapplicant?.hasCreditReport ? (
        <CoappFailedReportSection creditApp={creditApp} hasCoapplicant={hasCoapplicant} />
      ) : (
        <ApplicantFailedReportSection creditApp={creditApp} hasCoapplicant={hasCoapplicant} />
      )}
      <HelpSection />
    </main>
  )
}

export default FailedToObtainCreditReportForm
