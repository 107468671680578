import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { reportErrorToServer } from '@src/services/error-logger'
import { SubmitCoapplicantDto, useGetCreditApplicationById, useSubmitCoapplicant } from '@src/api/credit-api'
import { AcceptTermsCheckbox, AsyncActionButton, ScrollableAlert } from '@src/components'
import { AxiosError } from 'axios'
import { ESessionStorageKeys } from '@src/types/constants'
import { ValidationError } from 'yup'
import { getRecaptchaToken } from '@src/services/recaptcha'
import { ProvinceConfigs } from '@src/api/config-api'
import { Prequalification, buildPrequalificationSchemaObject } from './PrequalificationSchema'
import ReviewApplicantInfo from './ReviewApplicantInfo'

type Props = {
  gotoStep: (step: string) => void
  prequalification: Prequalification
  creditApplicationId: string
  provinceConfigs: ProvinceConfigs
}

const ReviewCoapplicant = ({ prequalification, gotoStep, creditApplicationId, provinceConfigs }: Props) => {
  const { t } = useTranslation()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const navigate = useNavigate()
  const [hasApplicantError, setHasApplicantError] = useState<boolean>(false)
  const [hasApplicantAddressError, setHasApplicantAddressError] = useState<boolean>(false)
  const [hasApplicantRevenueError, setHasApplicantRevenueError] = useState<boolean>(false)
  const [hasFormError, setHasFormError] = useState<boolean>(false)
  const [submitCoapplicant, isSubmitting] = useSubmitCoapplicant()

  const [creditApp, isFetchingCreditApp] = useGetCreditApplicationById(creditApplicationId, false)
  const isCreditAppPrequalified = creditApp?.normsDecision !== null
  const consentTitle = !isCreditAppPrequalified ? 'confirmation.consentTitle' : 'prequalification.pullCreditReport'

  const applicant = prequalification.applicant
  const prequalificationDataContainsErrors = async () => {
    setHasApplicantError(false)
    setHasApplicantAddressError(false)
    setHasApplicantRevenueError(false)
    setHasFormError(false)
    let formHasError = false
    try {
      await buildPrequalificationSchemaObject(provinceConfigs).validate(prequalification, {
        abortEarly: false,
      })
    } catch (error) {
      if (error instanceof ValidationError) {
        error.inner.forEach((err) => {
          const path = err.path as string
          if (path.includes('applicant')) {
            setHasApplicantError(true)
            formHasError = true
          }
          if (path.includes('applicant.currentAddress')) {
            setHasApplicantAddressError(true)
            formHasError = true
          }
          if (path.includes('applicant.currentJobs[0]')) {
            setHasApplicantRevenueError(true)
            formHasError = true
          }
        })
      }
    }
    if (formHasError) {
      setHasFormError(formHasError)
      window.scrollTo(0, 0)
    }
    return formHasError
  }

  const buildDtoAndSubmitCoapplicant = async (evt: { preventDefault: () => void }) => {
    evt.preventDefault()
    if ((await prequalificationDataContainsErrors()) === false) {
      try {
        const recaptchaToken = await getRecaptchaToken(executeRecaptcha, 'submit_postCreditApplicationAndPrequalify')
        const dto: SubmitCoapplicantDto = {
          recaptchaToken,
          coapplicant: prequalification.applicant,
          creditApplicationId,
        }
        await submitCoapplicant(dto)
        sessionStorage.removeItem(ESessionStorageKeys.COAPPLICANT_INFO)
        navigate(`/creditApplication/${creditApplicationId}`, { replace: true })
      } catch (error) {
        if ((error as AxiosError).response?.status === 424) {
          navigate('/recaptcha-validation-failed')
        } else {
          reportErrorToServer(error as Error)
        }
      }
    }
  }

  return (
    <section className="step-content">
      <h3 className="form-question">{t('review.title')}</h3>
      <div className="paragraph">
        <p>{t('review.description')}</p>
      </div>

      <form onSubmit={buildDtoAndSubmitCoapplicant}>
        <ScrollableAlert showAlert={hasFormError} type="error" message={t('review.formContainsErrors')} />
        <div className="review-wrap">
          <ReviewApplicantInfo
            applicant={applicant}
            hasApplicantError={hasApplicantError}
            hasApplicantAddressError={hasApplicantAddressError}
            hasApplicantRevenueError={hasApplicantRevenueError}
            gotoStep={gotoStep}
          />
        </div>

        <footer className="review-footer">
          <h3>
            <Trans i18nKey={consentTitle} />
          </h3>
          {isCreditAppPrequalified ? (
            <AcceptTermsCheckbox
              msgOnInvalid={t('common.acceptConditions')}
              id="acceptConditions"
              termId={` ${String(t('prequalification.acceptConditions'))}¹`}
            />
          ) : (
            <AcceptTermsCheckbox
              msgOnInvalid={t('common.acceptConditions')}
              id="acceptConditions"
              termId={t('confirmation.consent')}
            />
          )}

          <AcceptTermsCheckbox
            msgOnInvalid={t('common.acceptConditions')}
            id="acceptAuthorise"
            termId={t('common.acceptAuthorization')}
          >
            <br />
            {t('confirmation.authorization')}
            <u>
              <a href={t('common.policyLink')} target="_blank" rel="noreferrer">
                {t('contact.linkText')}
              </a>
            </u>
            {t('confirmation.authorizationEnd')}
          </AcceptTermsCheckbox>
          <AsyncActionButton
            type="submit"
            className="btn btn-blue"
            disabled={isSubmitting || hasFormError}
            isPending={isSubmitting}
            primary
          >
            <span>{t('review.getQuote')}</span>
            <i className="fa-regular fa-arrow-right" />
          </AsyncActionButton>

          {isCreditAppPrequalified && !isFetchingCreditApp && (
            <div style={{ borderTop: '1px solid black', marginTop: '5rem' }}>
              <p style={{ marginTop: '2rem' }}>¹{t('prequalification.priorToQualifyNotes.creditReport')}</p>
            </div>
          )}
        </footer>
      </form>
    </section>
  )
}

export default ReviewCoapplicant
